import { NgModule } from '@angular/core';
import { DashboardMenuModule, MenuItem } from '@x/dashboard/menu';

export const MENU_ITEMS: MenuItem[] = [
  {
    id: 'subscription-index',
    title: 'Subscriptions',
    route: '/admin/subscriptions',
    icon: 'id-card',
    parentId: 'sales',
    permissions: ['subscriptions_read'],
    position: 5,
  },
];

@NgModule({
  imports: [DashboardMenuModule.forChild(MENU_ITEMS)],
})
export class SubscriptionsMenuModule {}
